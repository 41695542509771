<template>
  <div id="plotly-charts" ref="chart" class="w100 h100"></div>
</template>

<script>
import Plotly from 'plotly.js-basic-dist-min';
import groupBy from 'lodash/groupBy';
import { translatedFilterTypes } from '@/util/indicators';

const config = {
  displaylogo: false,
  editable: false,
  displayModeBar: false,
  responsive: true // to make the chart responsive, make sure it's not messing up anything
};
const defaultHoverLabel = { bgcolor: '#FFF', bordercolor: '#000' };
const defaultMargin = { t: 10, b: 30, l: 35, r: 10 };
const defaultTitleFont = {
  family: 'DINNextLTPro-Regular',
  size: 14,
  color: '#363534b8'
};

export default {
  name: 'AnthropometryResultsCharts',
  props: {
    plot: {
      type: [Object, Array],
      required: true
    },
    type: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  computed: {
    translatedFilter() {
      return translatedFilterTypes.call(this, this.filters.type);
    }
  },
  mounted() {
    switch (this.type) {
      case 'gaussian':
        this.renderGaussian();
        break;
      case 'cumulative':
        this.renderCumulative();
        break;
      case 'probability':
        this.renderProbability();
        break;
      case 'groups':
        if (this.filters.group === 'months' && this.filters.type === 'muac') {
          this.renderAgeMonthGroup();
        } else {
          this.renderGroups();
        }
        break;
      case 'dist_whz_2':
      case 'dist_whz_3':
      case 'dist_haz_2':
      case 'dist_haz_3':
      case 'dist_waz_2':
      case 'dist_waz_3':
      case 'dist_edema':
      case 'dist_gam':
      case 'dist_sam':
        this.renderDistribution();
        break;
      default:
        break;
    }
  },
  methods: {
    buildSexTraces(plot, property, filters, options) {
      if (filters.group === 'sex') {
        const boys = plot[property].filter(x => x.group === 'm');
        const girls = plot[property].filter(x => x.group === 'f');
        return [
          {
            x: boys.map(x => x.x),
            y: boys.map(y => y.y),
            name: this.$tc('components.labels.boys', 2),
            ...options,
            line: {
              color: '#5398ef',
              width: 4
            }
          },
          {
            x: girls.map(x => x.x),
            y: girls.map(y => y.y),
            name: this.$tc('components.labels.girls', 2),
            ...options,
            line: {
              color: '#ff7f7f',
              width: 4
            }
          }
        ];
      }

      return [{
        x: this.plot[property].map(x => x.x),
        y: this.plot[property].map(y => y.y),
        name: this.$t('components.labels.ourData'),
        ...options,
        line: {
          color: '#ff7f7f',
          width: 4
        }
      }];
    },
    renderGaussian() {
      const { height } = this.$refs.chart.getBoundingClientRect();
      const trace1 = {
        x: this.plot.pdf_gaussian.map(x => x.x),
        y: this.plot.pdf_gaussian.map(y => y.y),
        name: this.$t('components.titles.whoStandards'),
        type: 'line',
        fill: 'tozeroy',
        line: {
          color: '#90e7c5',
          width: 4
        }
      };

      const dataTraces = this.buildSexTraces(this.plot, 'kde', this.filters, {
        type: 'line',
        fill: 'tozeroy'
      });

      Plotly.newPlot('plotly-charts', [trace1, ...dataTraces], {
        modebardisplay: false,
        height,
        showlegend: true,
        legend: {
          x: 1,
          xanchor: 'right',
          y: 1
        },
        margin: defaultMargin,
        xaxis: {
          title: this.$t('components.titles.zScores'),
          showgrid: false,
          titlefont: defaultTitleFont,
          zeroline: false
        },
        yaxis: {
          title: this.$t('components.titles.density'),
          titlefont: defaultTitleFont,
          range: [0, 0.45]
        }
      }, config);
    },
    renderCumulative() {
      const { height } = this.$refs.chart.getBoundingClientRect();
      const standardTrace = this.filters.group !== 'sex' && this.filters.type !== 'muac' ? [{
        x: this.plot.cdf_gaussian.map(x => x.x),
        y: this.plot.cdf_gaussian.map(y => y.y),
        name: this.$t('components.titles.whoStandards'),
        type: 'line',
        line: {
          color: '#90e7c5',
          width: 4
        }
      }] : [];
      const dataTraces = this.buildSexTraces(this.plot, 'ecdf', this.filters, {
        type: 'scatter',
        mode: 'markers'
      });

      const traces = [...standardTrace, ...dataTraces];
      const xAxisLabel = this.filters.type !== 'muac' ? this.$t('components.titles.zScores') : this.$t('values.muac');
      Plotly.newPlot('plotly-charts', traces, {
        modebardisplay: false,
        height,
        showlegend: true,
        legend: {
          x: 1,
          xanchor: 'right',
          y: 1
        },
        margin: defaultMargin,
        xaxis: {
          title: xAxisLabel,
          showgrid: false,
          titlefont: defaultTitleFont,
          zeroline: false
        },
        yaxis: {
          title: '',
          titlefont: defaultTitleFont
        }
      }, config);
    },
    renderProbability() {
      const { height } = this.$refs.chart.getBoundingClientRect();

      let traces = [];
      if (this.filters.group === 'sex') {
        const boys = this.plot.points.filter(x => x.group === 'm');
        const girls = this.plot.points.filter(x => x.group === 'f');
        traces = [
          {
            x: boys.map(x => x.x),
            y: boys.map(y => y.y),
            name: this.$tc('components.labels.boys', 2),
            type: 'scatter',
            mode: 'markers',
            line: {
              color: '#5398ef',
              width: 4
            }
          },
          {
            x: girls.map(x => x.x),
            y: girls.map(y => y.y),
            name: this.$tc('components.labels.girls', 2),
            type: 'scatter',
            mode: 'markers',
            line: {
              color: '#ff7f7f',
              width: 4
            }
          }
        ];
      } else {
        traces = [{
          x: this.plot.points.map(x => x.x),
          y: this.plot.points.map(y => y.y),
          name: '',
          type: 'scatter',
          mode: 'markers',
          line: {
            color: '#ff7f7f',
            width: 4
          }
        }];
      }
      const colors = ['#ff7f7f', '#5398ef'];
      const bestFitTrace = this.plot.lines.map((line, index) => ({
        x: [line.x1, line.x2],
        y: [line.y1, line.y2],
        mode: 'lines',
        type: 'scatter',
        name: this.$t('components.labels.bestFit'),
        line: {
          color: colors[index],
          width: 4
        }
      }));

      Plotly.newPlot('plotly-charts', [...traces, ...bestFitTrace], {
        modebardisplay: false,
        height,
        showlegend: this.plot.lines.length > 1,
        legend: {
          x: 1,
          xanchor: 'right',
          y: 1
        },
        margin: defaultMargin,
        xaxis: {
          title: this.$t('components.titles.zScores'),
          showgrid: false,
          titlefont: defaultTitleFont,
          zeroline: true
        },
        yaxis: {
          title: '',
          titlefont: defaultTitleFont,
          zeroline: true
        }
      }, config);
    },
    renderGroups() {
      const { height } = this.$refs.chart.getBoundingClientRect();
      const { group, type } = this.filters;
      let text = this.plot.map(y => `n=${y.n}`);
      let hovertemplate;
      if (group === 'cluster') {
        text = this.plot.map(y => [y.n, y.xbar]);
        let meanText = type === 'muac' ? this.$t('components.labels.meanMM', { number: '%{text[1]}' }) : this.$t('components.labels.meanZScore', { number: '%{text[1]}' });
        meanText += '<br>';
        hovertemplate = this.$t('components.labels.clusterMeanText', {
          x: '%{x}',
          text: '%{text[0]}',
          meanText
        });
      }
      if (group === 'months') {
        text = this.plot.map(y => [y.n, y.xbar]);
        let meanText = type === 'muac' ? this.$t('components.labels.meanMM', { number: '%{text[1]}' }) : this.$t('components.labels.meanZScore', { number: '%{text[1]}' });
        meanText += '<br>';
        hovertemplate = this.$t('components.labels.AgeGroupMeanText', {
          x: '%{x}',
          text: '%{text[0]}',
          meanText
        });
      }
      const data = {
        x: this.plot.map(x => x.groupvar),
        y: this.plot.map(y => y.xbar),
        error_y: {
          type: 'data',
          array: this.plot.map(y => {
            if (y.xbar < 0) {
              return 0 - y.sd;
            }
            return 0 + y.sd;
          }),
          arrayminus: this.plot.map(() => 0),
          visible: true
        },
        text,
        hovertemplate,
        textposition: 'top',
        marker: {
          color: '#ff7f7f'
        },
        type: 'bar'
      };
      Plotly.newPlot('plotly-charts', [data], {
        modebardisplay: false,
        height,
        showlegend: false,
        hoverlabel: defaultHoverLabel,
        legend: {
          x: 1,
          xanchor: 'right',
          y: 1
        },
        margin: defaultMargin,
        xaxis: {
          title: this.filters.group === 'months' ? this.$tc('components.titles.month', 2) : this.$tc('components.titles.cluster', 2),
          showgrid: false,
          autotick: false,
          titlefont: defaultTitleFont,
          zeroline: false
        },
        yaxis: {
          title: this.translatedFilter.toUpperCase(),
          showgrid: false,
          titlefont: defaultTitleFont,
          zeroline: false
        }
      }, config);
    },
    renderAgeMonthGroup() {
      const { height } = this.$refs.chart.getBoundingClientRect();
      const colors = ['#ff7f7f', '#f1ff2d', '#90e7c5'];
      const ageGrouped = groupBy(this.plot, 'age_bins');
      const muacGrouped = groupBy(this.plot, 'muac_bins');
      const hovertemplate = this.$t('components.labels.percentOfCasesInAgeGroup', {
        text: '%{text[1]}',
        x: '%{x}',
        y: '%{y}%',
        textTwo: '%{text[0]}'
      });
      const traces = Object.keys(muacGrouped).map((key, index) => ({
        x: Object.keys(ageGrouped),
        y: muacGrouped[key].map(y => y.p || 0),
        text: muacGrouped[key].map(value => [value.n, key.replace('%', '')]),
        name: key,
        type: 'bar',
        marker: {
          color: colors[index]
        },
        hovertemplate
      }));

      Plotly.newPlot('plotly-charts', traces, {
        modebardisplay: false,
        barmode: 'stack',
        hoverlabel: defaultHoverLabel,
        height,
        showlegend: true,
        margin: defaultMargin,
        xaxis: {
          title: this.$tc('components.titles.month', 2),
          showgrid: false,
          autotick: false,
          titlefont: defaultTitleFont,
          zeroline: false
        },
        yaxis: {
          title: '%',
          showgrid: false,
          titlefont: defaultTitleFont,
          zeroline: false
        }
      }, config);
    },
    renderDistribution() {
      const { height } = this.$refs.chart.getBoundingClientRect();
      const data = {
        x: this.plot.map(x => x.bin),
        y: this.plot.map(y => y.n),
        marker: {
          color: '#ff7f7f'
        },
        name: this.$tc('components.titles.case', 2),
        type: 'bar'
      };

      const poissonTrace = {
        x: this.plot.map(x => x.bin),
        y: this.plot.map(y => y.poisson),
        name: this.$t('components.titles.poissonDistribution'),
        marker: {
          color: '#90e7c5',
          size: 10
        },
        line: {
          color: '#000000',
          width: 1
        },
        mode: 'lines+markers'
      };

      const binomialTrace = {
        x: this.plot.map(x => x.bin),
        y: this.plot.map(y => y.nbinom),
        name: this.$t('components.titles.negBinomialDistribution'),
        marker: {
          color: '#5398ef',
          size: 10
        },
        line: {
          color: '#000000',
          width: 1
        },
        mode: 'lines+markers'
      };

      Plotly.newPlot('plotly-charts', [data, poissonTrace, binomialTrace], {
        modebardisplay: false,
        height,
        showlegend: true,
        legend: {
          x: 1,
          xanchor: 'right',
          y: 1
        },
        margin: defaultMargin,
        xaxis: {
          title: this.$t('components.titles.numberOfCasesInEachCluster'),
          showgrid: false,
          autotick: false,
          titlefont: defaultTitleFont,
          zeroline: false
        },
        yaxis: {
          title: this.$t('components.titles.numberOfClusters'),
          showgrid: false,
          titlefont: defaultTitleFont,
          zeroline: false
        }
      }, config);
    }
  }
};
</script>

<style lang="scss">
</style>
